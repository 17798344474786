import { useState, useEffect } from "react";
import Layout from "../layout/layout"
import Product from "../components/product";
import { StickyContainer, Sticky } from 'react-sticky';
import { Parallax } from 'react-scroll-parallax';
import sectionBackground from '../assets/section-background.svg';
import mint from "../config/mint.json"
import Web3 from 'web3'
import Modal from "../components/Modal";
import Tier1GIF from "../assets/syntax/Tier 1.gif";
import Tier2GIF from "../assets/syntax/Tier 2.gif";
import Tier3GIF from "../assets/syntax/Tier 3.gif";
import Tier4GIF from "../assets/syntax/Tier 4.gif";


function App() {
  const [supplyNormal, setSupplyNormal] = useState(0)
  const [authCode, setAuthCode] = useState('')
  const [isLogin, setIsLogin] = useState(false)
  const [supplyRare, setSupplyRare] = useState(0)
  const [supplySuper, setSupplySuper] = useState(0)
  const [supplyExtra, setSupplyExtra] = useState(0)
  const [normalCount, setNormalCount] = useState(0)
  const [rareCount, setRareCount] = useState(0)
  const [superCount, setSuperCount] = useState(0)
  const [extraCount, setExtraCount] = useState(0)
  const [normalPrice, setNormalPrice] = useState(0)
  const [rarePrice, setRarePrice] = useState(0)
  const [superPrice, setSuperPrice] = useState(0)
  const [extraPrice, setExtraPrice] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const [quantity, setQuantity] = useState(1);
  const [eth, setEth] = useState();
  const [index, setIndex] = useState(1);
  const [card, setCard] = useState(false);
  const [showPayWithCard, setShowPayWithCard] = useState(false);
  const [etherPriceInUSD, setEtherPrice] = useState(0)
  
  const web3 = new Web3(`${process.env.REACT_APP_INFURA_URI}`)
  const mintContract = new web3.eth.Contract(mint, `${process.env.REACT_APP_SMART_CONTRACT_URL}`);

  const checkoutId1 = `${process.env.REACT_APP_CHECKOUT_ID1}`
  const checkoutId2 = `${process.env.REACT_APP_CHECKOUT_ID2}`
  const checkoutId3 = `${process.env.REACT_APP_CHECKOUT_ID3}`
  const checkoutId4 = `${process.env.REACT_APP_CHECKOUT_ID4}`

  useEffect(() => {
    getSupplyNormal();
    getSupplyRare();
    getSupplySuper();
    getSupplyExtra();
    getNormalCount();
    getRareCount();
    getSuperCount();
    getExtraCount();
    getNormalPrice();
    getRarePrice();
    getSuperPrice();
    getExtraPrice();
    getActiveState();
    fetchEtherPrice();
  }, []);

  const fetchEtherPrice = async () => {
    const res = await fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD");
    const resData = await res.json();
   setEtherPrice(resData.USD)
  }


  const getActiveState = async () => {
    const value = await mintContract.methods.isPaused().call();
    setIsActive(!value)
  }

  const getSupplyNormal = async () => {
    const value = await mintContract.methods.MAX_SUPPLY_NORMAL().call();
    setSupplyNormal(value)
  }

  const getSupplyRare = async () => {
    const value = await mintContract.methods.MAX_SUPPLY_RARE().call();
    setSupplyRare(value)
  }

  const getSupplySuper = async () => {
    const value = await mintContract.methods.MAX_SUPPLY_SUPER().call();
    setSupplySuper(value)
  }

  const getSupplyExtra = async () => {
    const value = await mintContract.methods.MAX_SUPPLY_EXTRA().call();
    setSupplyExtra(value)
  }

  const getNormalCount = async () => {
    const value = await mintContract.methods.unclaimedSupply(0).call();
    setNormalCount(value)
  }

  const getRareCount = async () => {
    const value = await mintContract.methods.unclaimedSupply(1).call();
    setRareCount(value)
  }

  const getSuperCount = async () => {
    const value = await mintContract.methods.unclaimedSupply(2).call();
    setSuperCount(value)
  }

  const getExtraCount = async () => {
    const value = await mintContract.methods.unclaimedSupply(3).call();
    setExtraCount(value)
  }
  
  const getNormalPrice = async () => {
    const value = await mintContract.methods.NORMAL_CAR_PRICE().call();
    setNormalPrice(web3.utils.fromWei(value, 'ether'))
  }

  const getRarePrice = async () => {
    const value = await mintContract.methods.RARE_CAR_PRICE().call();
    setRarePrice(web3.utils.fromWei(value, 'ether'))
  }

  const getSuperPrice = async () => {
    const value = await mintContract.methods.SUPER_CAR_PRICE().call();
    setSuperPrice(web3.utils.fromWei(value, 'ether'))
  }

  const getExtraPrice = async () => {
    const value = await mintContract.methods.EXTRA_CAR_PRICE().call();
    setExtraPrice(web3.utils.fromWei(value, 'ether'))
  }
  const data = [
    { 
      index: "0",
      title: "Unique",
      category: "Tier 1",
      para: "Tier 1 is the entry-level Tier of the Syntax collection. The heritage racing colors of Carol Shelby and Briggs Cunningham inspire this eloquent theme. This Tier will give you token-gated access to the 3D model through our partner Tropee, as well as the ability to use the vehicle in any supported Metaverse ecosystem, gaming, and 3D printing applications.",
      ethValue: normalPrice,
      count: normalCount,
      supply: supplyNormal,
      checkoutId: checkoutId1,
      displayCardOption: true,
      image : Tier1GIF,
      modifier: setNormalCount,
      isActive: isActive,
      productDetail: ''
    }, 
    { 
      index: "1",
      classNameTitle: "green",
      category: "Tier 2",
      title: "Rare" ,
      para: "Tier 2 adds to the base-level experience of the unique vehicle by including an expansive pallet of exotic colors. This Tier will give you token-gated access to the 3D model through our partner Tropee, as well as the ability to use the vehicle in any supported Metaverse ecosystem, gaming, and 3D printing applications.",
      ethValue: rarePrice,
      count: rareCount,
      supply: supplyRare,
      displayCardOption: true,
      image : Tier2GIF,
      checkoutId: checkoutId2,
      modifier: setRareCount,
      isActive: isActive,
      productDetail: ''
    },
    {
      index: "2",
      category: "Tier 3",      
      classNameTitle: "orange",
      title: "Ultra Rare" ,
      image : Tier3GIF,
      para: "Tier 3 is the entry point for the curious car enthusiast. With a stunning wing for additional downforce, this Tier takes rare earth metals as inspiration. This Tier will grant you behind-the-scenes access to content generated throughout the build process. This Tier will give you token-gated access to the 3D model through our partner Tropee, as well as the ability to use the vehicle in any supported Metaverse ecosystem, gaming, and 3D printing applications.",
      ethValue: superPrice,
      count: superCount,
      displayCardOption: true,
      supply: supplySuper,
      checkoutId: checkoutId3,
      modifier: setSuperCount,
      isActive: isActive,
      productDetail: ''
    },
    {
      index: "3",
      title: "Limited",
      category: "Tier 4",     
      image : Tier4GIF, 
      para: "Tier 4 is for the luxury vehicle connoisseur and the concept design aficionado alike. With its complete body kit and exotic materials, this limited run will leave you speechless. Within this Tier, you will find various special edition vehicles including the Lieberman edition and the Cannabinol Run edition. This Tier will grant you access to the behind-the-scenes content and fully immersed VR build sessions (Dependent on funding targets). This Tier will give you token-gated access to the 3D model through our partner Tropee, as well as the ability to use the vehicle in any supported Metaverse ecosystem, gaming, and 3D printing applications. Holders of this tier will be first in line to buy the physical build-out.",
      ethValue: extraPrice,
      checkoutId: checkoutId4,
      displayCardOption: true,
      count: extraCount,
      supply: supplyExtra,
      modifier: setExtraCount,
      isActive: isActive,
      productDetail: ''
    }
  ]
  const verifyHandler = ()=> {
    console.log("------------")
    if(authCode === 'moon') {
      console.log("***********")
      document.getElementById('exampleModal').style.display = 'none'
      document.getElementById('exampleModal').classList.remove("show")
      setIsLogin(false)
    } else {
      setIsLogin(true)
    }
  }
  useEffect(() => {
    document.getElementById('exampleModal').style.display = 'block'
    document.getElementById('exampleModal').classList.add("show")
  }, [])

  return (
    <Layout>

      {/* ********** SECTION - HERO ********** */}
      <section className='y0-hero position-relative'>
        <div className="container">
          <div className="y0-hero__content">
            <div className="y0-hero__title">SYNTAX</div>
            <div className="y0-hero__para mt-2">
              <div>Y0’s Syntax</div>
              <div>is a dynamic or “smart” NFT.</div>
            </div>
          </div>
        </div>
      </section>

      {/* ********** SECTION - MINT ********** */}
        <section className="y0-section y0-section__sec position-relative overflow-hidden">
          <Parallax  y={[-100, 100]} className="position-absolute y0-hexagon__background">
            <div className="position-relative top-0 w-100" style={{ opacity: 0.6 }}><img className="img-fluid" src={sectionBackground} alt="" /></div>
            <div className="position-relative top-0 y0-hexagon__B w-100"><img className="img-fluid" src={sectionBackground} alt="" /></div>
          </Parallax>
            <StickyContainer>
              <div className="container position-relative">
                  <Sticky>{({ style, distanceFromTop }) =>
                    <div className="y0-title__page--sticky d-none d-md-block" style={style}>
                      <div className="y0-title__page-vertical">
                        <span className="d-inline-block">MINT...</span>
                      </div>
                    </div>
                  }</Sticky>

                  <div className="y0-title__page-vertical d-md-none ">
                    <span className="d-inline-block">MINT...</span>
                  </div>

                {data.map((value, index) => 
                  <Product
                    setCard={setCard}
                    setShowPayWithCard={setShowPayWithCard}
                    key={index}
                    index={value.index}
                    classNameTitle={value.classNameTitle}
                    title={value.title} 
                    para={value.para}
                    category={value.category}                    
                    count={value.count}
                    image={value.image}
                    etherPriceInUSD={etherPriceInUSD}
                    supply={value.supply}
                    checkoutId={value.checkoutId}
                    isActive={value.isActive}
                    ethValue={value.ethValue}
                    productDetail={value.productDetail}
                    setEth={setEth}
                    setQuantity={setQuantity}
                    setIndex={setIndex}
                  >
                  </Product>
                )}
              </div>
            </StickyContainer>
            <Modal 
              setCard={setCard}
              etherPriceInUSD={etherPriceInUSD}
              card={card}
              showPayWithCard={showPayWithCard}
              setShowPayWithCard={setShowPayWithCard}
              eth={eth}
              quantity={quantity}
              {...data[index]}
            />

        </section>

      {/* ********** MODAL - ENTER AUTHENTICATION ********** */}
      <div className="modal y0-modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog  modal-dialog-centered y0-modal__dialog">
          <div className="modal-content y0-modal__content overflow-hidden">
            <div className="position-absolute top-0 w-100" style={{ opacity: 0.6 }}><img className="" src={sectionBackground} alt="" /></div>
            <div className="modal-body">
              <div className="y0-modal__title mb-2">ENTER AUTHENTICATION CODE TO VERIFY</div>
                <input className="y0-btn__input mb-2" type="password" placeholder="Authentication code *" value={authCode} onChange={(e)=>setAuthCode(e.target.value)}/>
                {isLogin ?  <div className="y0-auth_message">Please input Correct Code.</div>: <div className="y0-auth_blank"></div>}
                <button className="y0-btn__modal" onClick={() => {verifyHandler();}}><div className="y0-btn__modal-inwrap">VERIFY</div></button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default App;
