import { useMemo } from "react";
import ABI from "./mint.json";
import { useAccount, useContract, useSigner } from "wagmi";

const useMintContract = () => {
  const { isConnected: active } = useAccount();
  const { data: signer } = useSigner();
  const contract = useContract({
    address: `${process.env.REACT_APP_SMART_CONTRACT_URL}`,
    abi: ABI,
    signerOrProvider: signer,
  });

  return useMemo(() => {
    if (active && signer) {
      return contract;
    }
  }, [active, signer]);
};

export { useMintContract };
