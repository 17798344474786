import "bootstrap/dist/js/bootstrap.bundle.min";
import { useState } from "react";
import Web3 from "web3";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import { useMintContract } from "../config/contract";

const WalletButton = (props) => {
  const { address: account, isConnected: active } = useAccount();
  const [isLoaded, setIsLoaded] = useState(false);
  const contract = useMintContract();

  //MINT
  const handlesell = async (_type, _quantity) => {
    const _amountOfEther = Math.floor(_quantity * props.price * 10 ** 18);

    try {
      if (active) {
        try {
          setIsLoaded(true);
          if (props.isActive) {
            // console.log("_quantity:  " + _quantity);
            // console.log("props.count:  " + props.count);
            // console.log("props.supply:  " + props.supply);
            if (Number(props.count) - Number(_quantity) > 0) {
              try {
                const web3 = new Web3(window.ethereum);
                // const accounts = await web3.eth.requestAccounts();
                // console.log(accounts[0]);
                console.log(account);
                let accountBalance = await web3.eth.getBalance(account);
                console.log("balance : " + accountBalance);
                const priceNeeded = _quantity * _amountOfEther;
                console.log("price : " + priceNeeded);
                if (accountBalance < priceNeeded) {
                  setIsLoaded(false);
                  document.getElementById("no-fund-modal-btn").click();
                } else {
                  let tx = await contract.claimTo(
                    account,
                    _quantity,
                    Number(_type),
                    {
                      value: _amountOfEther,
                    }
                  );
                  console.log("tx", tx);
                  props.setTransactionHash(tx.hash);
                  props.modifier(Number(props.count) - Number(_quantity));
                  setIsLoaded(false);
                  let success = "success-modal-btn";
                  let close = "mint-modal";
                  document.getElementById(close).click();
                  document.getElementById(success).click();
                }
              } catch (error) {
                console.log(error);
                setIsLoaded(false);
                props.setMessage(error.message.split("[")[0]);
                // document.getElementById('transaction-modal-btn').click();
              }
            } else {
              setIsLoaded(false);
              document.getElementById("sold-modal-btn").click();
            }
          } else {
            setIsLoaded(false);
            document.getElementById("active-modal-btn").click();
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <button className="y0-btn__modal mb-4">
      {active ? (
        <div
          className="y0-btn__modal-inwrap"
          onClick={() => handlesell(props.index, props.quantity)}
        >
          {isLoaded ? "MINTING..." : "MINT"}
        </div>
      ) : (
        <ConnectButton.Custom>
          {({
            account,
            chain,
            openChainModal,
            openConnectModal,
            authenticationStatus,
            mounted,
          }) => {
            const ready = mounted && authenticationStatus !== "loading";
            const connected =
              ready &&
              account &&
              chain &&
              (!authenticationStatus ||
                authenticationStatus === "authenticated");
            return (
              <div
                {...(!ready && {
                  "aria-hidden": true,
                  style: {
                    opacity: 0,
                    pointerEvents: "none",
                    userSelect: "none",
                  },
                })}
              >
                {(() => {
                  if (!connected) {
                    return (
                      <div
                        className="y0-btn__modal-inwrap"
                        onClick={openConnectModal}
                      >
                        CONNECT WALLET
                      </div>
                    );
                  }
                  if (chain.unsupported) {
                    return (
                      <div
                        className="y0-btn__modal-inwrap"
                        onClick={openChainModal}
                      >
                        Switch to Chain
                      </div>
                    );
                  }
                })()}
              </div>
            );
          }}
        </ConnectButton.Custom>
      )}
      {/* <ConnectButton.Custom>
        {active ? (
          <div
            className="y0-btn__modal-inwrap"
            onClick={() => handlesell(props.index, props.quantity)}
          >
            {isLoaded ? "MINTING..." : "MINT"}
          </div>
        ) : (
          <div className="y0-btn__modal-inwrap" onClick={handleConnectMetaMask}>
            {isUnsupportedChain ? "Switch to Chain" : "CONNECT WALLET"}
          </div>
        )}
      </ConnectButton.Custom> */}
    </button>
  );
};

export default WalletButton;
