import { useState, useEffect } from "react";
import iconArrow from '../assets/icon-arrow.svg';

const Product = (props) => {

  const [quantity, setQuantity] = useState(1);

  const ethValue = Number(props.ethValue);
  const [eth, setEth] = useState(ethValue);


  
  useEffect(() => {
    setEth(ethValue);
  }, [props.etherPriceInUSD, props.ethValue])
  
   
  //ONCHANGE
  const handleInputChange = (event) => {
    setQuantity(event.target.value);
    setEth(event.target.value * ethValue);

    if(quantity < 1) {
      setQuantity("1")
      setEth(ethValue)
    }
  }

  //INCREMENT
  const incQuantity = () => {
  
    if(quantity < props.supply && quantity < 10) {
      setQuantity(Number(quantity) + 1);
      setEth(eth + ethValue)
     }
  }


  //DECREMENT
  const decQuantity = () => {
    if(quantity>1) {
      setQuantity(quantity - 1);
      setEth(eth - ethValue)
     }
  }

  const handler = () => {
    props.setEth(eth);
    props.setQuantity(quantity);
    props.setIndex(props.index);
    props.setCard(false);
    props.setShowPayWithCard(false);
  }

  return (
    <div className="row y0-mint__row">
      <div className={`col-xl-12 y0-title__section y0-title__section--${props.classNameTitle}`}> {props.title} </div>
      <div className="col-xl-7">
        <img className="img-fluid" src={props.image} alt="" />
      </div>
      <div className="col-xl-5 position-relative">
        <div className="ps-xl-4 mt-3 mt-xl-0 y0-mint__right-content position-xl-absolute w-100">
          <div className="y0-section__subtitle">{props.category}</div>
          <div className="y0-mint__count-wrap mb-2 mt-1">
            <span className="y0-mint__count">{props.count}/<span className="y0-mint__count-total">{props.supply}</span></span> left
          </div>
          <p className="y0-para mb-5">{props.para}</p>

          <div className="y0-mint__cost-wrap mb-4">
            <div className="y0-mint__cost-eth">{Number(eth.toFixed(5))} ETH</div>
            <div className="y0-mint__cost-dollar">EST. ${(props.etherPriceInUSD * eth)?.toFixed(2) } USD</div>
          </div>
          <form action="" className="d-flex flex-wrap align-items-center">
            <div className="d-flex flex-wrap me-3 mb-3">
              <div className="y0-btn__quantity" onClick={decQuantity}>-</div>
              <input className="y0-input__quantity" type="number" value={quantity} onChange={handleInputChange} disabled={true} />
              <div className="y0-btn__quantity" onClick={incQuantity}>+</div>
            </div>
            <div 
              className="y0-button" 
              data-bs-toggle="modal" 
              data-bs-target="#productModal"
              onClick={handler}
            >
              <div className="y0-button__inwrap">
                MINT MY RIDE
                <img className="ms-3" src={iconArrow} alt="" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="col-md-12" dangerouslySetInnerHTML={{__html: props.productDetail}}></div>
    </div>
  )
}
export default Product;
