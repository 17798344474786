import { useState, useEffect } from 'react';
import close from '../assets/close.svg';
import WalletButton from "./iMet-WalletButton"
import sectionBackground from '../assets/section-background.svg';
import './card.css';
import { 
    PayWithCard,
    PaperSDKProvider,
    CreateWallet 
} from "@paperxyz/react-client-sdk";

const Modal = (props) => {
    const [emailAddress, setEmailAddress] = useState();
    const [walletAddress, setWalletAddress] = useState();
    const [transactionHash, setTransactionHash] = useState('');
    const [isEmail, setIsEmail] = useState(false);
    const [message, setMessage] = useState();
    const currentDate = new Date();

    const onPayWithCardTransferSuccess = (id) => {
        // setMessage('Transaction succeeded!');
        document.getElementById("mint-modal").click();
        document.getElementById("success-modal-btn").click();
        props.setCard(false);
        props.setShowPayWithCard(false);
        props.modifier(Number(props.count) - Number(props.quantity))
    };

    const onPayWithCardError = (paperSDKerror) => {
        setMessage(
        `Something went wrong! ${paperSDKerror.code}: ${paperSDKerror.error}`
        );
    };

    const onCreateWalletSuccess = (paperUser) => {
        setIsEmail(false);
        setWalletAddress(paperUser.walletAddress);
        setEmailAddress(paperUser.emailAddress);
        props.setShowPayWithCard(true);
    };

    const onCreateWalletError = (error) => {
        setMessage(`Your email could not be verified.`);
    };
    
    //Card Handler
    const cardHandler = () => {
        props.setCard(true);
    }

    const closeErrorModal = (id) => {
        document.getElementById(id).style.display = 'none'
        document.getElementById(id).classList.add("hide")
    }

    useEffect(() => {
        if (message) {
            document.getElementById('error-modal-btn').click();
        }
    }, [message])

    return (
        <>
            {/* ********** MODAL - PRODUCT ********** */}
            <div className="modal y0-modal fade" id="productModal" tabIndex="-1" aria-labelledby="productLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered y0-modal__dialog">
                    <div className="modal-content y0-modal__content overflow-hidden">
                        <div className="position-absolute bottom-0 w-100" style={{ opacity: 0.6 }}>
                            <img className="" src={sectionBackground} alt="" />
                        </div>
                        <div className="text-end">
                            <button type="button" className="y0-modal__close border-none position-absolute" id="mint-modal" data-bs-dismiss="modal" aria-label="Close">
                                <img src={close} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="y0-modal__title mb-2 text-uppercase">{props.title}</div>
                            <img className="img-fluid" src={props.image} alt="" />
                            <div>
                                <div className="d-lg-flex justify-content-between pt-2 pb-2">
                                    <div className="y0-mint__cost-wrap mb-4">
                                        <div className="y0-mint__cost-eth">{props.eth ? Number(props.eth.toFixed(5)): 0} ETH</div>
                                        <div className="y0-mint__cost-dollar">EST. ${props.eth ? (props.etherPriceInUSD * props.eth).toFixed(2) : 0 } USD</div>
                                    </div>
                                    <div className="y0-mint__count-wrap mb-2 mt-1">
                                    <span className="y0-mint__count">{props.count}/<span className="y0-mint__count-total">{props.supply}</span></span> left
                                    </div>
                                </div>
                                {props.card && props.displayCardOption
                                ?
                                    <div>
                                        {props.showPayWithCard 
                                        ?  
                                            <PaperSDKProvider 
                                                chainName={process.REACT_APP_CHAIN_NAME}
                                            >
                                                <PayWithCard 
                                                    checkoutId={props.checkoutId}
                                                    mintMethod={{
                                                        name: "claimTo",
                                                        args: {
                                                            _to: walletAddress,
                                                            _num: props.quantity,
                                                            _carType: Number(props.index)
                                                        },
                                                        payment: {
                                                            currency: "ETH",
                                                            value: (Number(props.quantity) * Number(props.ethValue)).toString()
                                                        }
                                                    }}
                                                    recipientWalletAddress={walletAddress}
                                                    emailAddress={emailAddress}
                                                    onPaymentSuccess={onPayWithCardTransferSuccess}
                                                    onError={onPayWithCardError}
                                                    quantity={Number(props.quantity)}
                                                    options={{
                                                        border:"none",
                                                        colorPrimary: "#42ff4f",
                                                        colorText: "#eeeeee",
                                                        borderRadius: 6,
                                                        fontFamily: "Open Sans",
                                                        colorBackground: '#0B0F17',
                                                    }}
                                                    allowtransparency="allowtransparency"
                                                />
                                            </PaperSDKProvider> 
                                        : 
                                            <div className="y0_paper_email">
                                                <input
                                                    className="y0_paper_email_input"
                                                    placeholder="Email address"
                                                    onChange={(e) => {
                                                        setEmailAddress(e.target.value);
                                                    }}
                                                />
                                                <CreateWallet
                                                    emailAddress={emailAddress}
                                                    onSuccess={onCreateWalletSuccess}
                                                    onError={onCreateWalletError}
                                                >
                                                    <button className="y0_paper_email_btn" onClick={()=>setIsEmail(true)} disabled={isEmail ? true: false}>Verify Email</button>
                                                </CreateWallet>

                                                {isEmail && <div className="y0_paper_email_login">Click the login link sent to <span>{emailAddress}</span> to continue.</div>}
                                            </div>
                                        }
                                    </div>
                                :
                                    <div>
                                        <WalletButton 
                                            setMessage={setMessage}
                                            index={props.index} 
                                            isActive={props.isActive} 
                                            quantity={props.quantity}  
                                            count={props.count} 
                                            supply={props.supply} 
                                            price={props.ethValue}
                                            modifier={props.modifier} 
                                            setTransactionHash={setTransactionHash}
                                        />
                                        {props.displayCardOption &&
                                        <div className="y0-btn__modal">
                                            <div 
                                                className="y0-btn__modal-inwrap" 
                                                onClick={cardHandler} >
                                                CARD
                                            </div>
                                        </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ********** MODAL - HANDLE BUTTONS ********** */}
            <div className="d-none" id="success-modal-btn" data-bs-toggle="modal" data-bs-target="#successModal"></div>
            <div className="d-none" id="mm-modal-btn" data-bs-toggle="modal" data-bs-target="#mmModal"></div>
            <div className="d-none" id="active-modal-btn" data-bs-toggle="modal" data-bs-target="#activeModal"></div>
            <div className="d-none" id="sold-modal-btn" data-bs-toggle="modal" data-bs-target="#soldOutModal"></div>
            <div className="d-none" id="transaction-modal-btn" data-bs-toggle="modal" data-bs-target="#transactionModal"></div>
            <div className="d-none" id="no-fund-modal-btn" data-bs-toggle="modal" data-bs-target="#noFundModal"></div>
            <div className="d-none" id="error-modal-btn" data-bs-toggle="modal" data-bs-target="#errorModal"></div>
            {/* ********** MODAL - SUCCESS ********** */}
            <div className="modal y0-modal fade" id="successModal" tabIndex="-1" aria-labelledby="successLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered y0-modal__dialog">
                    <div className="modal-content y0-modal__content overflow-hidden">
                        <div className="position-absolute bottom-0 w-100" style={{ opacity: 0.6 }}><img className="" src={sectionBackground} alt="" /></div>
                        <div className="text-end">
                            <button type="button" className="y0-modal__close border-none position-absolute" data-bs-dismiss="modal" aria-label="Close">
                                <img src={close} alt="" />
                            </button>
                        </div>
                        
                        <div className="modal-body">
                        <div className="y0-title__section mt-4 text-uppercase">CONGRATULATIONS</div>
                        <p className="mb-5 y0-para">Your order has been processed successfully.</p>
                        <img className="img-fluid" src={props.image} alt="" />
                        <div className="y0-modal__title mt-3 text-uppercase mb-5">{props.title}</div>
                        <div>
                            <div className=" pt-2 pb-5 mb-2">
                            <div className="w-100 y0-para d-flex justify-content-between mb-2">
                            <span>order No.</span>
                            <span>{transactionHash?<a href={process.env.REACT_APP_TX_URL + transactionHash} target="_blank" className="y0-transaction_hash" rel="noreferrer">{`${transactionHash?.slice(0, -58)}...${transactionHash?.substring(60)}`}</a>:
                            <a href={process.env.REACT_APP_ADDRESS_URL + walletAddress + '#tokentxnsErc721'} target="_blank" className="y0-transaction_hash" rel="noreferrer">{`${walletAddress?.slice(0, -38)}...${walletAddress?.substring(40)}`}</a>}</span>
                            </div>
                            
                            <div className="w-100 y0-para d-flex justify-content-between mb-2">
                            <span>Date & time</span>
                            <span>{new Intl.DateTimeFormat('en-US').format(currentDate)}</span>
                            </div>
                            <div className="w-100 y0-para d-flex justify-content-between mb-2">
                            <span>Total Price</span>
                            <span>{props.eth ? Number(props.eth.toFixed(5)) : 0} ETH</span>
                            </div>
                            </div>
                            <button  id="successButton" className="y0-btn__modal"  data-bs-dismiss="modal" aria-label="Close"><div className="y0-btn__modal-inwrap">UNDERSTOOD!</div></button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ********** MODAL - MM NOTIFICATION ********** */}
            <div className="modal y0-modal fade" id="mmModal" tabIndex="-1" aria-labelledby="mmLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered y0-modal__dialog">
                    <div className="modal-content y0-modal__content overflow-hidden">
                        <div className="position-absolute bottom-0 w-100" style={{ opacity: 0.6 }}><img className="" src={sectionBackground} alt="" /></div>
                        <div className="text-end">
                            <button 
                                type="button" 
                                className="y0-modal__close border-none position-absolute" 
                                id="mm-modal" 
                                data-bs-dismiss="modal" 
                                aria-label="Close"
                            >
                                <img src={close} alt="" />
                            </button>
                        </div>
                        
                        <div className="modal-body">
                        <div className="y0-title__section mb-1 text-uppercase">Failed</div>
                        <p className="mb-5 y0-para">Please install Metamask!!!</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* ********** MODAL - MINT ACTIVE ********** */}
            <div className="modal y0-modal fade" id="activeModal" tabIndex="-1" aria-labelledby="activeLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered y0-modal__dialog">
                    <div className="modal-content y0-modal__content overflow-hidden">
                        <div className="position-absolute bottom-0 w-100" style={{ opacity: 0.6 }}><img className="" src={sectionBackground} alt="" /></div>
                        <div className="text-end"><button type="button" className="y0-modal__close border-none position-absolute" data-bs-dismiss="modal" aria-label="Close"><img src={close} alt="" /></button></div>
                        
                        <div className="modal-body">
                        <div className="y0-title__section mb-1 text-uppercase">Failed</div>
                        <p className="mb-5 y0-para">Sale has not started yet</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* ********** MODAL - INSUFFICIENT FUND  ********** */}
            <div className="modal y0-modal fade" id="noFundModal" tabIndex="-1" aria-labelledby="activeLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered y0-modal__dialog">
                    <div className="modal-content y0-modal__content overflow-hidden">
                        <div className="position-absolute bottom-0 w-100" style={{ opacity: 0.6 }}><img className="" src={sectionBackground} alt="" /></div>
                        <div className="text-end"><button type="button" className="y0-modal__close border-none position-absolute" data-bs-dismiss="modal" aria-label="Close"><img src={close} alt="" /></button></div>
                        
                        <div className="modal-body">
                        <div className="y0-title__section mb-1 text-uppercase">No Fund</div>
                        <p className="mb-5 y0-para">You have insufficient fund</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* ********** MODAL - SoldOutModal ********** */}
            <div className="modal y0-modal fade" id="soldOutModal" tabIndex="-1" aria-labelledby="soldOutLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered y0-modal__dialog">
                    <div className="modal-content y0-modal__content overflow-hidden">
                        <div className="position-absolute bottom-0 w-100" style={{ opacity: 0.6 }}><img className="" src={sectionBackground} alt="" /></div>
                        <div className="text-end"><button type="button" className="y0-modal__close border-none position-absolute" data-bs-dismiss="modal" aria-label="Close"><img src={close} alt="" /></button></div>
                        
                        <div className="modal-body">
                        <div className="y0-title__section mb-1 text-uppercase">Failed</div>
                        <p className="mb-5 y0-para">Sold Out!!!</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* ********** MODAL - transactionModal ********** */}
            <div className="modal y0-modal fade" id="transactionModal" tabIndex="-1" aria-labelledby="transactionLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered y0-modal__dialog">
                    <div className="modal-content y0-modal__content overflow-hidden">
                        <div className="position-absolute bottom-0 w-100" style={{ opacity: 0.6 }}>
                            <img className="" src={sectionBackground} alt="" />
                        </div>
                        <div className="text-end">
                            <button type="button" className="y0-modal__close border-none position-absolute" data-bs-dismiss="modal" aria-label="Close">
                                <img src={close} alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="y0-title__section mb-1 text-uppercase">Failed</div>
                            <p className="mb-5 y0-para">Transaction Failed!!!</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* ********** MODAL - errorModal ********** */}
            <div className="modal y0-modal fade" id="errorModal" tabIndex="-1" aria-labelledby="errorLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered y0-modal__dialog">
                    <div className="modal-content y0-modal__content overflow-hidden">
                        <div className="position-absolute bottom-0 w-100" style={{ opacity: 0.6 }}>
                            <img className="" src={sectionBackground} alt="" />
                        </div>
                        <div className="text-end">
                            <button onClick={() => closeErrorModal("errorModal")} type="button" className="y0-modal__close border-none position-absolute" data-bs-dismiss="modal" aria-label="Close">
                                <img src={close} alt="" />
                            </button>
                        </div>
                        
                        <div className="modal-body">
                            <div className="y0-title__section mb-1 text-uppercase">Error</div>
                            <p className="mb-5 y0-para">{message}!!!</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal;
