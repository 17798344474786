import { useState, useEffect } from "react";
import IMetLayout from "../layout/imet-layout"
import IMetProduct from "../components/imet-product";
import { StickyContainer, Sticky } from 'react-sticky';
import { Parallax } from 'react-scroll-parallax';
import sectionBackground from '../assets/section-background.svg';
import mint from "../config/imet-mint.json"
import Web3 from 'web3'
import Modal from "../components/iMet-Modal";
import MiamiNFTPNG from "../assets/yo-miami-nft.png";

function MiamiNFT() {
  const [authCode, setAuthCode] = useState('')
  const [isLogin, setIsLogin] = useState(false)
  const [isActive, setIsActive] = useState(true)
  const [quantity, setQuantity] = useState(1);
  const [eth, setEth] = useState();
  const [index, setIndex] = useState(1);
  const [card, setCard] = useState(false);
  const [showPayWithCard, setShowPayWithCard] = useState(false);
  const [etherPriceInUSD, setEtherPrice] = useState(0)
  
  const web3 = new Web3(`${process.env.REACT_APP_INFURA_URI}`)
  const mintContract = new web3.eth.Contract(mint, `${process.env.REACT_APP_IMET_SMART_CONTRACT_URL}`);

  const checkoutId1 = `${process.env.REACT_APP_CHECKOUT_ID1}`

  useEffect(() => {
    getActiveState();
    fetchEtherPrice();
  }, []);

  const getActiveState = async () => {
    const value = await mintContract.methods.isPaused().call();
    console.log(value)
    setIsActive(!value)
  }

  const fetchEtherPrice = async () => {
    const res = await fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD");
    const resData = await res.json();
   setEtherPrice(resData.USD)
  }

  const data = [
    { 
      index: "0",
      title: "I met Y0 in Miami",
      category: "Y0 NFT Miami",
      para: "Miami Art Week 2022",
      ethValue: 0,
      checkoutId: checkoutId1,
      displayCardOption: false,
      image : MiamiNFTPNG,
      isActive: isActive,
    } 
  ]

  const verifyHandler = ()=> {
    console.log("------------")
    if(authCode === 'moon') {
      console.log("***********")
      document.getElementById('exampleModal').style.display = 'none'
      document.getElementById('exampleModal').classList.remove("show")
      setIsLogin(false)
    } else {
      setIsLogin(true)
    }
  }
  useEffect(() => {
    document.getElementById('exampleModal').style.display = 'none'
    document.getElementById('exampleModal').classList.remove("show") 
  }, [])

  return (
    <IMetLayout>
      {/* ********** SECTION - HERO ********** */}
      <section className='y0-hero position-relative'>
        <div className="container">
        </div>
      </section>
      {/* ********** SECTION - MINT ********** */}
      <section className="y0-section y0-section__sec position-relative overflow-hidden">
          <Parallax  y={[-100, 100]} className="position-absolute y0-hexagon__background">
            <div className="position-relative top-0 w-100" style={{ opacity: 0.6 }}><img className="img-fluid" src={sectionBackground} alt="" /></div>
            <div className="position-relative top-0 y0-hexagon__B w-100"><img className="img-fluid" src={sectionBackground} alt="" /></div>
          </Parallax>
            <StickyContainer>
              <div className="container position-relative">
                  <Sticky>{({ style, distanceFromTop }) =>
                    <div className="y0-title__page--sticky d-none d-md-block" style={style}>
                      <div className="y0-title__page-vertical">
                        <span className="d-inline-block">MINT...</span>
                      </div>
                    </div>
                  }</Sticky>

                  <div className="y0-title__page-vertical d-md-none ">
                    <span className="d-inline-block">MINT...</span>
                  </div>

                {data.map((value, index) => 
                  <IMetProduct
                    setCard={setCard}
                    setShowPayWithCard={setShowPayWithCard}
                    key={index}
                    index={value.index}
                    classNameTitle={value.classNameTitle}
                    title={value.title} 
                    para={value.para}
                    category={value.category}                    
                    count={value.count}
                    image={value.image}
                    supply={value.supply}
                    checkoutId={value.checkoutId}
                    etherPriceInUSD={etherPriceInUSD}
                    isActive={value.isActive}
                    ethValue={value.ethValue}
                    productDetail={value.productDetail}
                    setEth={setEth}
                    setQuantity={setQuantity}
                    setIndex={setIndex}
                  >
                  </IMetProduct>
                )}
              </div>
            </StickyContainer>
            <Modal 
              setCard={setCard}
              card={card}
              etherPriceInUSD={etherPriceInUSD}
              showPayWithCard={showPayWithCard}
              setShowPayWithCard={setShowPayWithCard}
              eth={eth}
              quantity={quantity}
              {...data[index]}
            />

        </section>
        
      {/* ********** MODAL - ENTER AUTHENTICATION ********** */}
      <div className="modal y0-modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog  modal-dialog-centered y0-modal__dialog">
          <div className="modal-content y0-modal__content overflow-hidden">
            <div className="position-absolute top-0 w-100" style={{ opacity: 0.6 }}><img className="" src={sectionBackground} alt="" /></div>
            <div className="modal-body">
              <div className="y0-modal__title mb-2">ENTER AUTHENTICATION CODE TO VERIFY</div>
                <input className="y0-btn__input mb-2" type="password" placeholder="Authentication code *" value={authCode} onChange={(e)=>setAuthCode(e.target.value)}/>
                {isLogin ?  <div className="y0-auth_message">Please input Correct Code.</div>: <div className="y0-auth_blank"></div>}
                <button className="y0-btn__modal" onClick={() => {verifyHandler();}}><div className="y0-btn__modal-inwrap">VERIFY</div></button>
            </div>
          </div>
        </div>
      </div>
    </IMetLayout>
  )
}

export default MiamiNFT;
