import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useEffect, useCallback, useState } from 'react';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { MetaMaskconnector } from '../config/web3Conf';
import { useMintContract }  from "../config/imet-contract";
import Web3 from 'web3';

const IMetWalletButton = (props) => {

  const { active, activate, account, error } = useWeb3React()
  const [isLoaded, setIsLoaded] = useState(false);
  const isUnsupportedChain = error instanceof UnsupportedChainIdError
  const contractMint = useMintContract();
  useEffect(() => {
    if (active) {
      localStorage.setItem('shouldEagerConnect', true)
      localStorage.setItem('account', account)
    }
  }, [active])
  
  const handleConnectMetaMask = useCallback(() => {
    const {ethereum} = window;
    if(!ethereum) {
      document.getElementById('mm-modal-btn').click();
    }
    activate(MetaMaskconnector)
  },[activate])

  //MINT
  const handlesell = async (_type, _quantity) => {
    
    const _amountOfEther = Math.floor(_quantity * props.price * 10 ** 18);
    
    try {
      if(active) {
        try {
          setIsLoaded(true)
          if(props.isActive){
            // console.log("_quantity:  " + _quantity);
            // console.log("props.count:  " + props.count);
            // console.log("props.supply:  " + props.supply);
            if(1 ==1) {
              try {
                const web3 = new Web3(window.ethereum);
                // const accounts = await web3.eth.requestAccounts();
                // console.log(accounts[0]);
                console.log(account);
                let accountBalance = await web3.eth.getBalance(account);
                console.log("balance : " + accountBalance);
                const priceNeeded =  _quantity * _amountOfEther;
                console.log("price : " + priceNeeded);
                if (accountBalance < priceNeeded) {
                  setIsLoaded(false)
                  document.getElementById('no-fund-modal-btn').click();
                } else {
                  let tx = await contractMint.methods.mint(account).send({from:account, value: _amountOfEther})
                  console.log("tx", tx.transactionHash)
                  props.setTransactionHash(tx.transactionHash)
                  props.modifier(Number(props.count) - Number(_quantity))
                  setIsLoaded(false)
                  let success = "success-modal-btn"
                  let close = "mint-modal"
                  document.getElementById(close).click();
                  document.getElementById(success).click();
  
                }
              } catch (error) {
                console.log(error);
                setIsLoaded(false)
                props.setMessage(error.message.split("[")[0])
                // document.getElementById('transaction-modal-btn').click();
              }
            } else {
              setIsLoaded(false)
              document.getElementById('sold-modal-btn').click();
            }
          } else {
            setIsLoaded(false)
            document.getElementById('active-modal-btn').click();
          }
        } catch (error) {
          console.log("error", error)
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <button className="y0-btn__modal mb-4"> 
      {
        active ? 
        <div className="y0-btn__modal-inwrap" onClick={() => handlesell(props.index, props.quantity)}>{isLoaded? 'MINTING...': 'MINT'}</div> :
        <div className="y0-btn__modal-inwrap" onClick={handleConnectMetaMask}>{isUnsupportedChain ? 'Switch to Chain' : 'CONNECT WALLET'}</div>
      }
    </button>
  )
}

export default IMetWalletButton
