import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useEffect, useState } from 'react';

const Layout = (props) => {

  const [scrollDirection, setScrollDirection] = useState(null);
  const [logoPosition, setLogoPosition] = useState(null)

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;

      if(scrollY > 2) {
        setLogoPosition(true)
      } else {
        setLogoPosition(false)
      }
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [scrollDirection]);
  

  return (
    <div className="y0">
      <header className={`y0-header ${logoPosition ? "y0-header__scrolledDown" : ""} ${scrollDirection === "down" ? "hidden" : "visible"}`}>
        <div className="container d-flex justify-content-between">
          <nav className={`navbar navbar-expand-lg w-100 pt-0 pb-0 ${logoPosition ? "align-items-center" : "align-items-start"}`}>
            <div className="y0-header__brand">
              <a href="">
                <img className={`y0-header__logo ${logoPosition && "d-none" }`} src="./images/logo.svg" alt="logo" />
                <img className={`y0-header__logo ${!logoPosition ? "d-none" : ""}`} style={{ height: 50 }} src="./images/logo-beta.svg" alt="logo" />
              </a>
            </div>
            
            <button className="navbar-toggler y0-header__btn-toggle" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <div className="y0-header__menu-line"></div>
              <div className="y0-header__menu-line"></div>
              <div className="y0-header__menu-line"></div>
            </button>
            <div className="collapse navbar-collapse y0-header__navbar-collapse" id="navbarSupportedContent">
              <ul className="y0-header__menu-items navbar-nav align-items-lg-center">
                <li className="y0-header__menu-item nav-item">
                  <a className="nav-link active" href="https://y0nft.com/#features">FEATURES</a>
                </li>
                <li className="y0-header__menu-item nav-item">
                  <a className="nav-link" href="https://y0nft.com/#artist">ARTIST</a>
                </li>
                <li className="y0-header__menu-item nav-item">
                  <a className="nav-link" href="https://y0nft.com/#roadmap">ROADMAP</a>
                </li>
                <li className="y0-header__menu-item nav-item">
                  <a className="nav-link" href="https://y0nft.com/#collections">COLLECTIONS</a>
                </li>
                <li className="y0-header__menu-item nav-item">
                  <a className="nav-link" href="https://y0nft.com/#about">ABOUT</a>
                </li>
                <li className="y0-header__menu-item nav-item">
                  <a className="nav-link" href="https://y0nft.com/#contact">CONTACT</a>
                </li>
                <li className="y0-header__menu-item--buynow nav-item px-3 px-lg-0">
                  <a className="nav-link y0-header__buynow" href="https://y0nft.com/#collections">BUY NOW</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>

      <main className="y0-main overflow-hidden"> 
        {props.children}
      </main>

      <footer className="y0-footer y0-section pb-5">
        <div className="container">
          <div className='text-center'>
            <img src="./images/logo-beta.svg" />

            <div className='my-4 mt-5 y0-footer__content'>
              <div className='mb-2'>Y0 LLC</div>
              <div>1925 Century Park East, Suite 1700, Los Angeles, CA 90067</div>
            </div>
            <ul className='d-flex justify-content-center mb-0'>
              {/* <li className='y0-footer__social-link me-3'>
                <a href=""><img src="./images/youtube.svg" alt="" /></a>
              </li> */}
              <li className='y0-footer__social-link me-3'>
                <a href="https://www.instagram.com/y0_nft/" target="_blank" rel="noreferrer"><img src="./images/instagram.svg" alt="" /></a>
              </li>
              <li className='y0-footer__social-link me-3'>
                <a href="https://discord.com/invite/y0" target="_blank" rel="noreferrer"><img src="./images/discord.svg" alt="" /></a>
              </li>
              <li className='y0-footer__social-link '>
                <a href="https://twitter.com/y0_nft" target="_blank" rel="noreferrer"><img src="./images/twitter.svg" alt="" /></a>
              </li>
            </ul>
            <div className='text-uppercase y0-footer__copyrights-text py-4'>COPYRIGHT - 2022 | ALL RIGHTS RESERVED | <a href='' className=''>terms & Conditions</a> | <a href='' className=''>Licenses</a></div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Layout
